<template>
  <div id="bbForm">
    <van-form ref="form" class="form" style="margin-bottom:65px">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <custom-select ref="activityTypeCode" required @change="selectClickHandle" v-model="form.activityTypeCode" placeholder="请选择活动类型" labelKey="name" valueKey="id" label="活动类型" :columns="optionType" />
        <van-field v-model="form.cultivateName" placeholder="请填写活动名称" label="活动名称" :required="true" :rules="[{ required: true  } ]" />
        <van-field v-model="form.activityLocation" placeholder="请填写活动地点" label="活动地点" :required="true" :rules="[{ required: true  } ]" />
        <custom-date-picker required ref="startAt" v-model="form.startAt" label="活动开始日期" placeholder="点击选择活动开始日期" @change="changeOption1" />
        <custom-date-picker required ref="endAt" v-model="form.endAt" label="活动结束日期" placeholder="点击选择活动结束日期" @change="changeOption2" />
        <van-field v-model="form.activityLong" readonly label="活动天数" />
        <van-field v-model="form.situation" rows="3" :required="true" :rules="[{ required: true  } ]" class="textarea-style" placeholder="请填写活动介绍" arrow-direction="" label="活动介绍" type="textarea" maxlength="300" />
        <custom-date-picker required ref="startTime" v-model="form.startTime" label="开始时间" placeholder="点击选择活动结束日期" @change="changeOption2" />
      </van-cell-group>
      <van-cell-group title="附件">
        <file-upload v-if="refsh" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" v-model="fileList" miz="STU_PUNISH_RESOLVE" biz="storage" :multiple="3" v-on:uploadResponse="uploadResponse" list-type="list" :auto-upload="true" :max-size="1">
          <span slot="tip" class="upload-tips">
            支持上传任意文件,文件最大1M
          </span>
        </file-upload>
      </van-cell-group>
      <van-cell-group title="参与人员">
        <van-field v-model="form.createBy" placeholder="请填写发起人" :required="true" :rules="[{ required: true }]" readonly label="发起人" />
        <van-field v-model="form.createPhone" placeholder="请填发起人写电话" :required="true" :rules="[{ required: true, message: '请填写发起人电话' },
        {pattern: /^1[3-9]\d{9}$/, message: '请输入合法的手机号/电话号'}]" label="发起人电话" />
        <van-field v-model="form.techName" placeholder="请填写负责教师" @click="clickTeacherHandle" readonly :required="true" :rules="[{ required: true }]" label="负责教师" />
        <van-field v-model="form.techPhone" placeholder="请填写负责教师电话" label="负责教师电话" />
      </van-cell-group>
      <van-cell-group title="负责学生" style="margin-bottom:74px">
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="item,index in form.stuInfos" :key="index" :title="item.perName" :value="item.perCode" :name="index">
            <div> <span class="collapse-title">院&nbsp&nbsp&nbsp&nbsp&nbsp系: </span><span class="collapse-text">{{item.collName}}</span></div>
            <div> <span class="collapse-title">专&nbsp&nbsp&nbsp&nbsp&nbsp业: </span><span class="collapse-text">{{item.specName}}</span></div>
            <div> <span class="collapse-title">班&nbsp&nbsp&nbsp&nbsp&nbsp级: </span><span class="collapse-text">{{item.className}}</span></div>
            <div> <span class="collapse-title">活动角色: </span><span class="collapse-text">{{item.role}}</span></div>
            <div> <span class="collapse-title">活动时长: </span><span class="collapse-text">{{item.duration}}</span></div>
            <div> <span class="collapse-title">联系电话: </span><span class="collapse-text">{{item.phone}}</span></div>
            <div style="margin-top:10px">
              <van-button style="height:26px" round type="warning" plain hairline @click="delStu(index,item.id)">删除</van-button>
              <van-button style="height:26px;margin-left:15px" round type="primary" plain hairline @click="editStudent(index,item)">编辑</van-button>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div class="form-addList-button">
          <van-button type="primary" native-type="button" size="small" @click="addStudent">添加负责学生</van-button>
        </div>
      </van-cell-group>

      <div class="submit-button shadow-style">
        <div>
          <van-button round block type="primary" :loading="loading" @click="doSubmit()">提 交</van-button>
        </div>
      </div>
    </van-form>

    <!-- 负责学生表单 -->
    <van-popup v-model="show" @close="studentClose" closeable position="bottom" :style="{ height: '520px', backgroundColor:'#F5F5F5' }">
      <div class="wj-title">{{isAddStu?'新增参与学生':'编辑参与学生'}}</div>
      <van-form ref="stu" class="stu">
        <van-field v-model="stuData.perCode" label="学号" readonly @click="searchStuInfo" placeholder="点击选择学生信息" :required="true" :rules="[{ required: true  } ]" />
        <van-field v-model="stuData.perName" label="姓名" placeholder="姓名" readonly :required="true" :rules="[{ required: true  } ]" />
        <van-field v-model="stuData.gradeNo" label="年级" placeholder="年级" readonly :required="true" :rules="[{ required: true  } ]" />
        <van-field v-model="stuData.collName" label="院系" placeholder="院系" readonly :required="true" :rules="[{ required: true  } ]" />
        <van-field v-model="stuData.specName" label="专业" placeholder="专业" readonly :required="true" :rules="[{ required: true  } ]" />
        <custom-select ref="role" required @change="selectClickHandle1" v-model="stuData.role" placeholder="请选择角色类型" labelKey="name" valueKey="name" label="角色" :columns="optionType2" />
        <custom-select ref="duration" required @change="selectClickHandle2" v-model="stuData.duration" placeholder="请选择活动时长" labelKey="name" valueKey="name" label="时长" :columns="optionType3" />
        <van-field v-model="stuData.phone" label="联系方式" placeholder="请填写联系方式" />
      </van-form>
      <div style="margin: 16px;">
        <van-button round block type="primary" @click="submitAddStu" native-type="submit" style="font-size:15px">保 存</van-button>
      </div>
    </van-popup>
    <!-- 学生选择 -->
    <van-popup v-model="showStu" @close="closeStu" closeable position="bottom" :style="{ height: '520px', backgroundColor:'#F5F5F5' }">
      <div class="wj-title">学生选择</div>
      <van-search class="wj-search" v-model="search" @input="onSearchHandle" placeholder="请输入学生学号或姓名检索" />
      <div class="wj-body">
        <van-cell v-for="(row,index) in stuList" :key="index" class="stu-card">
          <template #title>
            <span style="font-size:15px;font-weight:bold"> {{row. stuName +"  " +row. stuNo}}</span>
          </template>
          <template #label>
            <span style="font-size:14px;">{{ row.sqecName +'-'+  row.className}}</span>
          </template>
          <template #right-icon>
            <van-button class="table-edit-btn" @click="selectRow(row)" plain round size="small" type="primary">选择</van-button>
          </template>
        </van-cell>
      </div>
    </van-popup>
    <!-- 负责老师选择 -->
    <van-popup v-model="teacherInfoShow" @close="teacherClose" closeable position="bottom" :style="{ height: '80%', backgroundColor:'#F5F5F5' }">
      <div class="wj-title">负责老师选择</div>
      <van-search class="wj-search" v-model="search2" @input="onSearchHandle2" placeholder="请输入学生工号或姓名检索" />
      <div class="wj-body">
        <van-cell v-for="(row,index) in teacherData" :key="index" class="stu-card">
          <template #title>
            <span style="font-size:15px;font-weight:bold"> {{row.staffName +"  " +row.staffNo}}</span>
          </template>
          <template #label>
            <span style="font-size:14px;">{{row.unit && row.unit.name? row.unit.name : ""}}</span>
          </template>
          <template #right-icon>
            <van-button class="table-edit-btn" @click="selectRow2(row)" plain round size="small" type="primary">选择</van-button>
          </template>
        </van-cell>
      </div>
    </van-popup>
  </div>
</template>
<script>

import fileUpload from "@/components/FileUpload";
import { validCustom } from "@/utils/index";
import { getConfigNoPage } from '@/api/modules/evaluation/evaluationConfig'
import { getStuinfoNotPageSmall } from '@/api/modules/xj/stuInfo'
import { mapGetters } from "vuex";
import { getStaffInfosSmallNoPage } from '@/api/modules/xgdw/xgStaffInfo'
import { add, edit, getById } from '@/api/modules/evaluation/evaluationCultivate'

export default {
  name: "EvaluationActivityForm",
  dicts: ['evaluation_activity_type', 'xj_nj', 'xj_xqgl', 'xj_shzt'],
  components: { fileUpload },
  data() {
    return {
      form: {
        id: null,
        cultivateName: null,
        activityTypeCode: null,
        activityTypeName: null,
        activityLocation: null,
        activityLong: null,
        startAt: null,
        endAt: null,
        startTime: null,
        situation: null,
        fileId: null,
        activeFileId: null,
        updateBy: null,
        updateTime: null,
        createTime: null,
        createBy: null,
        createNo: null,
        createDept: null,
        createPhone: null,
        techNo: null,
        techName: null,
        techPhone: null,
        search: null,
        stuInfos: [],
      },
      optionType: [],
      optionType2: [],
      optionType3: [],
      loading: false,
      refsh: true,
      fileList: [],
      customRuleFileds: ["activityTypeCode", "startAt", "endAt", "startTime"],
      customRuleFileds2: ["role", "duration"],
      show: false,
      isAddStu: true,
      editIndex: 0,
      activeNames: [],
      stuData: {
        perCode: "",
        perName: "",
        collName: "",
        collNo: "",
        specName: "",
        specCode: "",
        className: "",
        classNo: "",
        gradeNo: "",
        type: 3,
        phone: "",
        role: "",
        roleId: "",
      },
      showStu: false,
      search: "",
      stuList: [],
      search2: "",
      teacherData: [],
      teacherInfoShow: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      roles: 'roles',
      token: 'token'
    }),
  },
  created() {
    this.$nextTick(() => {
      // 配置类型
      getConfigNoPage({ type: 1 }).then((res) => {
        if (res) {
          // 类型 
          const filteredList = res.filter(r => r.id === 16);
          this.optionType = filteredList[0].children.map((e) => {
            e.children = undefined
            return e;
          });
          //角色
          const filteredList2 = res.filter(r => r.id === 15);
          this.optionType2 = filteredList2[0].children.map((e) => {
            e.children = undefined
            return e;
          });
          // 时长
          const filteredList3 = res.filter(r => r.id === 14);
          this.optionType3 = filteredList3[0].children.map((e) => {
            e.children = undefined
            return e;
          });
        }
      })

      if (this.$route.query && this.$route.query.id) {
        getById(this.$route.query.id).then((res) => {
          if (res) {
            this.fileList = res.fileId.split(",")
            this.form = { ...res }
            this.fresh = false
            this.$nextTick(() => {
              this.fresh = true
            })
          }
        })
      } else {
        this.form.createBy = this.user.nickName
        this.form.createNo = this.user.id
        this.initAdd();
      }

    })
  },
  methods: {
    //  ------------------------------  负责教师选择 ------------------------------
    clickTeacherHandle() {
      this.teacherInfoShow = true
    },
    teacherDelete() {
      this.form.s.splice(index, 1);
    },
    teacherClose() {
      this.teacherInfoShow = false;
      this.teacherData = []
      this.search2 = ""
    },
    onSearchHandle2(queryString) {
      getStaffInfosSmallNoPage({ staffNo: queryString })
        .then((staffList) => {
          if (staffList && staffList.length) {
            this.teacherData = staffList
          } else {
            this.teacherData = []
          }
        })
    },
    selectRow2(teacher) {
      this.form.techNo = teacher.staffNo
      this.form.techName = teacher.staffName
      this.teacherClose()
    },
    // ---------------------------------------------------------------------------
    selectClickHandle(val, data) {
      this.form.activityTypeName = data.name
    },
    selectClickHandle1(val, data) {
      this.stuData.roleId = data.id
    },
    selectClickHandle2(val, data) {
      this.stuData.durationId = data.id
    },
    initAdd() {
      let roles = this.user.roles.map((item) => {
        return item.id
      })
      if (roles.indexOf(6) > -1) {
        getStuinfoNotPageSmall({ stu: this.user.username }).then((stulist) => {
          if (stulist && stulist.length) {
            let data = stulist[0]
            this.form.stuInfos.push({
              perCode: data.stuNo,
              perName: data.stuName,
              collName: data.collName,
              collNo: data.collNo,
              specName: data.sqecName,
              specCode: data.sqecCode,
              className: data.className,
              classNo: data.classNo,
              gradeNo: data.gradeNo,
              type: 3,
              phone: "",
              role: "组织策划者",
              roleId: 17
            })
            this.form.stuInfos.splice(999999, 1)
          }
        })
      }
    },
    // -------------------------------------选择学生 --------------------------------------
    onSearchHandle(queryString) {
      getStuinfoNotPageSmall({ stu: queryString }).then((stu) => {
        if (stu && stu.length) {
          this.stuList = stu
        } else {
          this.stuList = []
        }
      })
    },
    searchStuInfo() {
      this.showStu = true
    },
    closeStu() {
      this.showStu = false
      this.search = ""
      this.stuList = []
    },
    selectRow(data) {
      let activtyLong = this.getOptionalType3();
      let alreadyStu = this.form.stuInfos.map((e) => e.perCode)
      if (!(alreadyStu.indexOf(data.stuNo) > -1)) {
        this.stuData = {
          perCode: data.stuNo,
          perName: data.stuName,
          collName: data.collName,
          collNo: data.collNo,
          specName: data.sqecName,
          specCode: data.sqecCode,
          className: data.className,
          classNo: data.classNo,
          gradeNo: data.gradeNo,
          type: 3,
          phone: "",
          role: "普通参与",
          roleId: 19,
          duration: activtyLong.lable,
          durationId: activtyLong.id,
        }
      }
      this.showStu = false
    },
    getOptionalType3() {
      if (this.form.activityLong <= 1) {
        return { lable: "正常时间", id: 1 };
      } else if (this.form.activityLong >= 2 && this.form.activityLong <= 3) {
        return { lable: "2-3天", id: 11 };
      } else if (this.form.activityLong >= 4 && this.form.activityLong <= 7) {
        return { lable: "4-7天", id: 12 };
      } else if (this.form.activityLong >= 7) {
        return { lable: "7天以上", id: 13 };
      } else {
        return { lable: "正常时间", id: 1 };
      }
    },
    // -------------------------------------选择学生 --------------------------------------
    changeOption1(val) {
      if (this.form.startAt && this.form.endAt) {
        const startDate = new Date(this.form.startAt);
        const endDate = new Date(this.form.endAt);
        const diffDays = this.getDaysDiff(startDate, endDate);
        if (diffDays <= 0) {
          this.$notify({
            type: "warning",
            message: "请选择合理的时间段",
            duration: 1500,
          });
          this.form.startAt = ""
          return
        }
        this.form.activityLong = diffDays
      }
    },
    changeOption2(val) {
      if (this.form.startAt && this.form.endAt) {
        const startDate = new Date(this.form.startAt);
        const endDate = new Date(this.form.endAt);
        const diffDays = this.getDaysDiff(startDate, endDate);
        if (diffDays <= 0) {
          this.$notify({
            type: "warning",
            message: "请选择合理的时间段",
            duration: 1500,
          });
          this.form.endAt = ""
          return
        }
        this.form.activityLong = diffDays
      }
    },
    getDaysDiff(startDate, endDate) {
      const ONE_DAY_MS = 1000 * 60 * 60 * 24; // 一天的毫秒数
      const startMs = startDate.getTime();   // 开始日期的毫秒数
      const endMs = endDate.getTime();       // 结束日期的毫秒数
      const diffMs = endMs - startMs;        // 两个日期之间的毫秒数
      const diffDays = Math.floor(diffMs / ONE_DAY_MS);  // 相差的天数（向下取整）
      return diffDays;
    },
    uploadResponse(fileIds) {
      if (fileIds && fileIds[0]) {
        this.form.fileId = fileIds[0]
      }
    },
    // ----------------------------------------学生新增--------------------------------------------------
    submitAddStu() {
      this.$refs.stu.validate().then(
        (res) => {
          if (validCustom(this.customRuleFileds2, this)) {
            // 验证通过数据提交
            if (this.isAddStu) {
              this.form.stuInfos.push(this.stuData)
            } else {
              this.form.stuInfos.splice(this.editIndex, 1, this.stuData);
            }
            this.studentClose();
          } else {
            this.$notify({
              type: "warning",
              message: "有必填项未填写",
              duration: 1500,
            });
          }
        },
        (err) => {
          validCustom(this.customRuleFileds2, this);
          this.$notify({
            type: "warning",
            message: "有必填项未填写",
            duration: 1500,
          });
        }
      );
    },
    studentClose() {
      this.show = false
      this.stuData = {
        perCode: "",
        perName: "",
        collName: "",
        collNo: "",
        specName: "",
        specCode: "",
        className: "",
        classNo: "",
        gradeNo: "",
        type: 3,
        phone: "",
        role: "",
        roleId: "",
      }
    },
    addStudent() {
      if (!this.form.startAt && !this.form.endAt) {
        this.$notify({
          message: "请选择活动时间周期",
          title: '提示',
          type: 'warning',
          duration: 2500,
        })
        return;
      }
      this.show = true
      this.isAddStu = true

    },
    editStudent(index, data) {
      this.show = true
      this.isAddStu = false
      this.stuData = data;
      this.editIndex = index;
    },
    delStu(index, data) {
      this.form.stuInfos.splice(index, 1)
    },
    // -------------------------------------------------------------------------------------------
    doEdit() {
      edit(this.form)
        .then((res) => {
          this.$notify({
            title: '修改成功',
            type: 'success',
            duration: 2500,
          })
          this.loading = false
          this.cancel()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    doAdd() {
      add(this.form)
        .then((res) => {
          this.$notify({
            message: '添加成功',
            type: 'success',
            duration: 2000,
          })
          this.loading = false
          this.cancel()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    doSubmit() {
      this.$refs.form.validate().then(
        (res) => {
          if (validCustom(this.customRuleFileds, this)) {
            if (!this.form.fileId) {
              this.$notify({
                type: "warning",
                message: "请上传附件材料",
                duration: 1500,
              });
              return;
            }
            if (!this.form.stuInfos && this.form.stuInfos.length === 0) {
              this.$notify({
                type: "warning",
                message: "请选择参与学生",
                duration: 1500,
              });
              return;
            }
            // 验证通过数据提交
            this.$dialog
              .confirm({
                title: "提示",
                message: "你确定要进行提交操作吗？",
              })
              .then(() => {
                this.loading = true;
                if (this.$route.query && this.$route.query.id) {
                  this.doEdit();
                } else {
                  this.doAdd();
                }
              })
              .catch(() => {
                // on cancel
              });
          } else {
            this.$notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        },
        (err) => {
          validCustom(this.customRuleFileds, this);
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
    cancel() {
      this.form = {
        id: null,
        cultivateName: null,
        activityTypeCode: null,
        activityTypeName: null,
        activityLocation: null,
        activityLong: null,
        startAt: null,
        endAt: null,
        startTime: null,
        situation: null,
        fileId: null,
        activeFileId: null,
        updateBy: null,
        updateTime: null,
        createTime: null,
        createBy: null,
        createNo: null,
        createDept: null,
        createPhone: null,
        techNo: null,
        techName: null,
        techPhone: null,
        search: null,
        stuInfos: [],
      }
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
#bbForm {
  .form {
    ::v-deep.radio-other-info {
      .van-cell {
        .van-field__label {
          width: 11.2em;
        }
      }
    }

    .van-field__label {
      width: 110px;
    }
    .van-cell {
      font-size: 15px;
      line-height: 32px;
      height: 50px;
    }
    .textarea-style {
      height: 100%;
    }
  }
  .stu {
    ::v-deep.radio-other-info {
      .van-cell {
        .van-field__label {
          width: 11.2em;
        }
      }
    }

    .van-field__label {
      width: 110px;
    }
    .van-cell {
      font-size: 15px;
      line-height: 32px;
      height: 50px;
    }
    .textarea-style {
      height: 100%;
    }
  }
  .wj-title {
    text-align: center;
    line-height: 55px;
    font-size: 17px;
    font-weight: bold;
    height: 50px;
    background-color: #fff;
  }
  .wj-search {
  }
  .stu-card {
    margin-top: 18px;
  }
  .wj-body {
    overflow-y: scroll;
    height: calc(80vh - 100px);
    padding: 0 18px;
  }
}
</style>